
.home-container-b2b{
  height: 100%;
  background-size: cover;
}
  
  .widgets-container{
    /* margin: auto; */
    display: flex;
    padding: 20px;
    gap: 20px;
  }
  
  
  .home-container .charts-container {
    padding: 5px 20px;
  }
  
  .home-container .list-container {
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    padding: 20px;
    margin: 20px;
  }
  
  .home-container .list-container .listTitle {
    font-weight: 500;
    color: gray;
    margin-bottom: 15px;
  }
  
  .icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  
  .delete-icon,
  .view-icon,
  .download-icon {
    color: #e74c3c;
    cursor: pointer;
  }
  
  
  .queue-item,
  .processed-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 10px;
    background-color: #fff;
  }
  
  .queue-item:hover,
  .processed-item:hover {
    background-color: #f5f5f5;
  }
  .form-container-b2b {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px; 
    border-radius: 10px; 
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
  }
  .upload-section-b2b {
    text-align: center;
  }
  .upload-section-b2b h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  .section {
    margin-bottom: 20px;
  }
  .section h2 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .upload-input {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
  }
  .submit-button {
    padding: 15px;
    background-color: #0645b1;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease; 
  }
  .submit-button:hover {
    background-color: #45a049;
  }
  .heading-text{
  margin: 0px 0px 24px;
  margin-top: 18px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 300;
    line-height: 1.167;
    letter-spacing: -0.01562em;
    color:#3498db;
    font-size: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .single-head-adjust{
  display:flex;
  justify-content:center;
  align-items: center;
  margin: auto;
  }
  .card-container {
  display: flex;
  justify-content: space-around;
  margin: 20px;
  }
  .card {
  text-align: center;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 200px;
  }
  .adjustment{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* margin: auto; */
    margin-top: 10rem;
    margin-bottom: 5rem;
  }
  .card-logo {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
  }
  .card-text {
  margin-top: 10px;
  }
  /* .heading-text {
  text-align: center;
  color: #3498db;
  font-size: 36px; 
  font-weight: bold; 
  margin-bottom: 20px; 
  letter-spacing: 2px; 
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  } */
  .card-container {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  .card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
  padding: 20px;
  border-radius: 8px;
  width: 130px;
  height: 210px;  
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  transition: transform 0.3s ease-in-out;
  }
  .card:hover {
  transform: translateY(-5px); 
  }
  .card-icon {
  font-size: 60px;
  margin-bottom: 10px;
  color: #3498db;
  }
  .card-text {
  font-size: 16px;
  color: #555;
  text-align: center;
  }
  .icon-large {
  font-size: 70px !important;
  }
  .reviews-container {
  box-sizing: border-box;
  margin: 0px;
  flex-direction: row;
  flex-basis: 100%;
  -webkit-box-flex: 0;
  flex-grow: 0;
  max-width: 100%;
  text-align: center;
  }
  .reviews-title {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
  }
  .review-image {
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  .img-one{
  width: 720px;
  height: 650px;
  }
  .img-two{
  max-width: 700px;
  height: 550px;
  }
  .img-three{
  max-width: 650px;
  height: 450px;
  }
  .img-four{
  width: 750px;
  height: 200px;
  }
  .img-five{
  width: 600px;
  height: 500px;
  }
  .img-six{
  width: 450px;
  height: 650px;
  }
  .image-container{
  display: flex;
  flex-direction: row;
  }
  .carousel {
  width: 100%; 
  margin: auto; 
  overflow: hidden;
  }
  .carousel-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  }
  .carousel-item {
  text-align: center;
  }
  .slick-slide img {
  width: 100%;
  border-radius: 8px;
  }
  .slick-center img {
  transform: scale(1.2); 
  }
  .slick-center {
  display: flex;
  justify-content: center;
  align-items: center;
  }
  .slick-dots {
  bottom: 20px; 
  }
  .slick-dots li button:before {
  font-size: 12px;
  color: #3498db;
  }
  .slick-prev,
  .slick-next {
  font-size: 24px;
  color: #3498db;
  }
  .slick-prev {
  left: -30px;
  }
  .slick-next {
  right: -30px; 
  }
  .slick-slider {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; 
  }
  .slick-list {
  width: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
  }
  .slick-slide {
  text-align: center;
  }
  .slick-slide img {
  margin: 0 auto;
  max-width: 100%;
  height: auto;
  }
  .platform-heading{
  margin: 16px 0px 0.35em;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 3rem;
  line-height: 1.167;
  letter-spacing: 0em;
  }
  .pdf-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  }
  .carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px; 
  }
  .listTitle{
  display: flex;
  align-items: center;
  justify-content: space-between;
  }
  header{
    display: flex;
    justify-content: center;
    width: 100%;
  }
  
  @media only screen and (max-width: 768px) {
    .entire-component{
      display: flex;
      justify-content: center;
       flex-direction: column; 
    }
    .home-container {
      display: flex;
      flex-direction: column;
      align-items: center; 
      justify-content: center; 
    }
    .widgets-container {
      /* max-width: 100%; */
      display: flex;
      flex-direction: column;
      /* width: 20%; */
      /* flex-wrap: wrap; */
      /* justify-content: space-around; */
      /* align-items: center;  */
    }
    .pdf-container {
      display: flex;
      flex-direction:column;
      justify-content:center;
      align-items: center;
      width: 100% !important;
      text-align: center;

    }
    header{
      display: flex;
      justify-content: center;
      width: 80%;
    }
    .adjustment{
      display: flex;
      width: 100%;
      justify-content: center;
      margin-bottom: 10rem;
    }
    .heading-text{
      font-size: 20px;
    }
    .list-container{
      width: 100%;
    }.table-text-latest{
      font-size: 15px;
    }
  }

  .widget-card-dash {
    border: 1px solid #ccc; 
    padding: 1rem; 
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    margin-top: 2rem; 
  }
  
  .md-w-full {
    width: 100%;
  }
  
  .mt-8 {
    margin-top: 2rem;
  }
  
  .md-mt-0 {
    margin-top: 0;
  }
  
  .md-py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  
  .md-px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  
  .widget-content-dash {
    width: 12rem; 
    margin: 0 1.25rem; 
    padding: 0 1.5rem; 
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
  }
  
  .widget-title-dash {
    font-size: 1.125rem;
    font-weight: 600;
  }
  
  .widget-count-dash {
    color: black;
    font-family: 'Manrope', sans-serif; 
    font-size: 3.75rem; 
    font-weight: 700; 
  }
  
  .widget-image-con-dash {
    width: 4rem; 
    height: 4rem; 
    object-fit: cover;
    object-position: center;
    border: 1px solid #ccc; 
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .widget-image-dash {
    width: 2rem; 
    height: 2rem; 
  }
  
  .submit-button-b2c {
    display: flex;
    justify-content: center;
    padding: 0.5rem 2rem; 
    margin-top: 1.5rem; 
    font-size: 1rem;
    font-weight: 600;
    color: #ffffff;
    background-color: #38a169; 
    border-radius: 0.25rem;
    white-space: nowrap;
    cursor: pointer;
    text-decoration: none; 
    text-align: center;
    border:none;
    transition: background-color 0.3s ease;
    margin-bottom: 70px;
  }
  
  .submit-button-b2c:hover {
    background-color: #2c7a4e;
  }
  
  @media (max-width: 768px) {
    .submit-button-b2c {
      padding: 0.5rem 1.25rem; 
    }
  }
  

  .pdf-upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 6px;
    margin-top: 4px;
    max-width: 100%;
    width: 570px;
    height: 150px;
    background-color: #fff;
    border: 1px solid #d1d5db; 
    border-radius: 12px;
    overflow: auto;
    position: relative;
    z-index: 1;
  }
  
  

  
  .pdf-upload-container .file-container .upload-info .upload-text {
    font-weight: 600;
    color: #059669; 
    font-family: Manrope, -apple-system, Roboto, Helvetica, sans-serif !important;
  
  }
  
  .pdf-upload-container .file-container .upload-info .upload-text span {
    margin-right: 4px;
  }
  
  .pdf-upload-container .file-container .file-wrapper {
    background-color: #f7fafc; 
    border-radius: 5px;
    font-family: Manrope, -apple-system, Roboto, Helvetica, sans-serif !important;
  
  }
  
  .pdf-upload-container .file-container .file-wrapper .pdf-info {
    display: flex;
    justify-content: space-between;
    align-items: center; 
    padding: 10px; 
  }
  
  .pdf-upload-container .file-container .file-wrapper .pdf-info .pdf-name {
    flex-grow: 1; 
    margin-right: 10px; 
  }
  
  .pdf-upload-container .file-container .file-wrapper .delete-button {
    background-color: #fb3232; 
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    font-family: Manrope, -apple-system, Roboto, Helvetica, sans-serif !important;
  
  }
  
  .pdf-container-b2c{
    display: flex;
    gap: 5px;
  }
  
  .pdf-list-container_ {
    max-height: 100px;
    overflow-y: auto; 
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .pdf-list_ {
    display: flex;
    flex-direction: column;
  }
  
  .pdf-item_ {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px;
    margin-bottom: 4px;
    background-color: #f7f7f7;
    border-radius: 5px;
  }
  
  .pdf-name_ {
    flex: 1;
    margin: 0;
    font-size: 12px; 
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .pdf-delete-button_ {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
  }
  
  .pdf-delete-button_:hover {
    color: #fb3232;
  }

  .pdf-upload-container-b2c-n{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* cursor: pointer; */
    padding: 6px;
    margin-top: 4px;
    max-width: 100%;
    width: 570px;
    height: 150px;
    /* background-color: #fff; */
    /* border: 1px solid #d1d5db;  */
    border-radius: 12px;
    
}

.upload-more-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.upload-more-button:hover {
  background-color: #0056b3;
}

.pdf-wrapper_ {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 10px 15px;
  margin-bottom: 10px;
  transition: box-shadow 0.3s ease;
}

.pdf-wrapper_:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.pdf-name_ {
  font-size: 16px;
  color: #333;
  flex-grow: 1;
  margin-right: 10px;
}

.pdf-delete-button_ {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.pdf-delete-button_:hover {
  opacity: 0.7;
}

@media screen and (max-width: 768px) {
  .pdf-upload-container{
    width: 300px;
  }
}

.search-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 10;
}

.search-input {
  flex: 1;
  padding: 10px 15px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 30px 0 0 30px;
  border-right: none;
}

.search-btn {
  padding: 10px 15px;
  font-size: 16px;
  color: #fff;
  background-color: #4285f4;
  border: 1px solid #4285f4;
  border-radius: 0 30px 30px 0;
  cursor: pointer;
}


.search-btn:hover {
  background-color: #357ae8;
}

.search-btn i {
  font-size: 16px;
}

.search-results-container {
  position: absolute;
  top: 100%; /* Position it directly below the search input */
  left: 0;
  right: 0;
  max-height: 200px; /* Optional: limit the height of the dropdown */
  overflow-y: auto;  /* Add scrolling if the results exceed the max height */
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 100; /* Ensure it's on top */
}
.search-results-title {
  font-size: 18px;
  font-weight: bold;
}

.search-results-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.search-result-item {
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
  cursor: pointer;
}

.test-code-name{
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-top: 10px;
  padding: 5px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  border-radius: 5px;
}

.remove-icon {
  margin-left: 10px;
  cursor: pointer;
  font-size: 25px;
  color: #ff0000;
}


.search-results-dropdown{
  position: absolute;
  list-style-type: none;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: white;
  /* border: 1px solid #ccc; */
  border-radius: 4px;
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15); */
}

.main-heading-text {
  text-align: center;
  margin-top: 20px;
  font-weight: bold;
  font-size: 40px;
  color: #3498db;
}


@media (max-width: 1200px) {
  .main-heading-text {
    font-size: 35px;
  }
}

@media (max-width: 992px) {
  .main-heading-text {
    font-size: 30px;
  }
}

@media (max-width: 768px) {
  .main-heading-text {
    font-size: 25px;
  }
}

@media (max-width: 576px) {
  .main-heading-text {
    font-size: 20px;
  }
}
