.uploads-review-section-b2b {
  display:flex;
  flex-direction:column;
  align-items: center;
  align-self: stretch;
  display: flex;
  justify-content: center;
  /* padding: 24px 60px; */
  margin-top:5px;
}
@media (max-width: 991px) {
  .uploads-review-section-b2b {
    padding: 0 20px;
  }
}
.uploads-review-heading-dash {
  /* align-self:start; */
  color: #000;
  font: 500 24px/100% Manrope, -apple-system, Roboto, Helvetica, sans-serif;
}
@media (max-width: 991px) {
  .uploads-review-heading-dash {
    max-width: 100%;
  }
}
.uploads-table-container-dash {
  border-radius: 8px;
  /* border-left: 1px solid rgba(229, 231, 235, 1); */
  /* border-right: 1px solid rgba(229, 231, 235, 1); */
  border-bottom: 1px solid rgba(229, 231, 235, 1);
  background-color: #fff;
  width: 100%;
  max-width: 1088px;
}
@media screen and (max-width: 768px) {
  .uploads-table-container-dash {
    display: none;
  }
  .uploads-table-container-dash-mobile {
    display: block;
    /* width: 95%; */
    margin-top: 20px;
  }
  .pagination-mobile-b2b {
    display:block;
  }
}

@media screen and (min-width: 768px) {
  .uploads-table-container-dash-mobile {
    display: none;
  }
  .pagination-mobile-b2b {
    display:none;
  }
}

.uploads-review-table-dash {
  border-radius: 8px 8px 0 0; 
  border-left: 1px solid rgba(229, 231, 235, 1);
  border-right: 1px solid rgba(229, 231, 235, 1);
  border-top: 1px solid rgba(229, 231, 235, 1);
  background-color: #fff;
  width: 100%;
  max-width: 1088px;
  margin-top: 32px;
}

@media (max-width: 991px) {
  .uploads-review-table-dash {
    max-width: 100%;
  }
}
th, td {
  font-family: Inter, sans-serif;
  padding: 16px 12px;
  text-align: left;
}
th {
  background-color: #f9fafb;
  font-size: 14px;
  white-space: nowrap;
}
td {
  background-color: #fff;
  font-size: 16px;
  line-height: 150%;
}
.status-b2b {
  display: flex;
  align-items: center;
  gap: 4px;
}
.status-b2b img {
  width: 8px;
}
.completed-b2b {
  background-color: #f0fdf4;
  color: #166534;
  border-radius: 16px;
  padding: 3px 8px;
}
.in-queue-b2b {
  background-color: #fef2f2;
  color: #991b1b;
  border-radius: 16px;
  padding: 3px 8px;
}
.evaluation-btn-b2b {
  display: flex;
  justify-content: center;
  border-radius: 8px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
  background-color: #4f46e5;
  padding: 10px 16px;
}
.actions-b2b {
  display: flex;
  cursor:pointer;
  gap: 8px;
}
.actions-b2b img {
  width: 20px;
}
.pagination-b2b {
  display: flex;
  width: 100%;
  gap: 20px;
  font-size: 16px;
  color: #1f2937;
  max-width: 1088px;
  justify-content: space-between;
  padding: 12px 0;
}
.prev-b2b {
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(229, 231, 235, 1);
  padding: 10px 16px;
  margin-left: 8px;
}

.prev-mobile-b2b{
  height:40px ;
  width: 115px;
}

.next-mobile-b2b{
  height:40px ;
  width: 115px;
}

.next-b2b {
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(229, 231, 235, 1);
  padding: 10px 16px;
  margin-right: 8px;
}

.prev-b2b img, .next-b2b img {
  width: 20px;
}

.dashboard {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 48px 60px 24px;
  margin-top: 0rem;
}
@media (max-width: 991px) {
  .dashboard {
    padding: 0 20px;
  }
}
@media screen and (max-width: 768px) {
  .dashboard {
    margin-top: 1.5rem;
  }
  
}
.data-overview {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1088px;
}
@media (max-width: 991px) {
  .data-overview {
    max-width: 100%;
  }
}
.dashboard-title {
  align-self:center;
  color: #000;
  font: 500 24px/100% Manrope, -apple-system, Roboto, Helvetica, sans-serif;
}
.widgets-container-dash {
  margin-top: 32px;
  display: flex;
  gap: 20px;
}
@media (max-width: 991px) {
  .widgets-container-dash {
    flex-direction: column;
    align-items: stretch;
    gap: 0;
  }
}
.widget-card-dash {
  display: flex;
  justify-content: space-between;
  border: 1px solid rgba(229, 231, 235, 1);
  border-radius: 8px;
  background-color: var(--Background-base, #fff);
  padding: 24px;
  width: 33%;
  flex-grow: 1;
}
@media (max-width: 991px) {
  .widget-card-dash {
    width: 100%;
    margin-top: 32px;
    padding: 10px 20px;
  }
}
.widget-content-dash {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.widget-title-dash {
  /* font: 500 16px Manrope, sans-serif; */
  color: #000;
  font-family: Manrope;
  font-size: 20px;
  font-style: normal;
  font-weight: 550;
  line-height: 20px; 
}
.widget-count-dash {
  color: #000;
  font-family: Manrope;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 44px; 
  letter-spacing: -2.16px;
}

.widget-percentage-dash {
  color: var(--green-600, #16A34A);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; 
}

.widget-icon-dash{
  border: 1px solid rgba(234, 236, 240, 1);
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  width: 38px;
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  border-radius: 5px;
  /* padding: 10px 15px 20px 25px; */
   /* padding:3px; */
  /*border-radius: var(--radius-lg, 10px);
  border: 1px solid var(--Component-colors-Components-Icons-Featured-icons-Modern-featured-icon-modern-border, #EAECF0);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05); */
}

.widget-image-con-dash{
    width: 48px;
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    border: 1px solid rgba(234, 236, 240, 1);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    align-self: start;
    display: flex;
    align-items: center;
    justify-content: center;
}

.widget-image-dash {
  height: 25px;
  width: 25px;
}

@media screen and (max-width: 768px) {

.pagination-b2b {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.pagination-b2b button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.pagination-b2b button:disabled {
  background-color: #ccc;
}
}

@media (max-width: 768px) {
.submission-details-dash-mobile {
  border: 1px solid rgba(240, 240, 240, 1);
  border-radius: 8px;
  background-color: #f7f7f7;
  max-width: 600px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  margin-top: 16px;
}

.details-row-dash-mobile {
  display: flex;
  margin-bottom: 24px;
  gap: 3rem;
}

.details-column-dash-mobile {
  display: flex;
  flex-direction: column;
  margin-right: 16px;
}

.details-id-dash-mobile, .details-date-dash-mobile {
  color: var(--Content-Base-main, #1F2937);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 550;
  line-height: 24px; 
}

.details-time-dash-mobile{
  color: var(--Content-Base-main, #1F2937);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 550;
  line-height: 24px; 
  width: 100px;
}


.dash-mobile {
    font-size: 16px; 
    overflow: hidden; 
    display: -webkit-box; 
    -webkit-box-orient: vertical ;
    -webkit-line-clamp: 3; 
    line-clamp: 3; 
    white-space: normal; 
    text-overflow: ellipsis;
}

@media (max-width: 600px) {
  .dash-mobile {
    font-size: 14px; 
    width: 300px; 
  }
}


.details-label-dash-mobile, .status-label-dash-mobile {
  font: 400 14px/1.14 "Inter", -apple-system, "Roboto", "Helvetica", sans-serif;
}


.status-icon-dash-mobile {
  width: 8px;
  aspect-ratio: 1;
  margin-right: 4px;
}

.status-text-dash-mobile {
  font-family: Inter, sans-serif;
}

.action-buttons-dash-mobile {
  display: flex;
  justify-content: space-between;
  background-color: #ededed;
  border-radius: 0 0 8px 8px;
  padding: 16px;
}

.read-report-dash-mobile {
  display: inline-block;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06), 0 1px 3px rgba(0, 0, 0, 0.1);
  background-color: var(--Interactions-buttons-primary-default, #4f46e5);
  color: var(--Content-Inverted-main, #f3f4f6);
  padding: 10px 16px;
  font: 400 16px "Inter", sans-serif;
}

.icons-dash-mobile {
  display: flex;
  /* justify-content: space-between; */
  gap: 8px;
}

.icon-dash-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(230, 230, 230, 1);
  border-radius: 8px;
  background-color: #fff;
  padding: 10px 16px;
}

.action-icon-dash-mobile {
  width: 20px;
  aspect-ratio: 1;
}
}

.data-overview {
padding: 20px;
text-align: center;
}

.dashboard-title {
font-size: 24px;
/* margin-bottom: 20px; */
font-weight: bold;
color: #333;
}

.widgets-container {
display: flex;
justify-content: center;
align-items: center;
gap: 20px;
margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
.widgets-container{
  width: 90%;
  flex-direction: column;
}
}

.dropdown-container-dash {
/* margin: 20px; */
display: flex;
justify-content: center;
align-items: center;
}

.dropdown-label {
font-size: 18px;
margin-right: 10px;
font-weight: bold;
color: #444;
}

.dropdown-select {
padding: 10px 15px;
font-size: 18px;
font-weight: bold;
color: #fff;
background-color: #007bff;
border: none;
border-radius: 5px;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
transition: background-color 0.3s ease, box-shadow 0.3s ease;
cursor: pointer;
}

.dropdown-select:hover {
background-color: #0056b3;
color:#f3f4f6;
box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.dropdown-select:focus {
outline: none;
box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
}

.heading-container-dash{
display: flex;
width: 100%;
justify-content: space-between;
align-items: center;
}

.tooltip {
position: relative;
display: inline-block;
cursor: pointer;
}

.tooltip .tooltiptext {
visibility: hidden;
width: 300px; 
background-color: black;
color: #fff;
text-align: left;
border-radius: 6px;
padding: 10px;
position: absolute;
z-index: 1;
bottom: 125%; 
left: 50%;
margin-left: -150px; 
opacity: 0;
transition: opacity 0.3s;
white-space: normal; 
}

.tooltip:hover .tooltiptext {
visibility: visible;
opacity: 1;
}

.evaluation-button-dash {
  display: flex;
  height: 40px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color:#f0fdf4;
  border-radius: var(--rounded-lg, 8px);
  background: var(--Interactions-buttons-primary-default, #4F46E5);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10);
}

.image-container-modal {
display: flex;
gap: 20px;
}

.review-question-images {
height:400px;
width:400px;
}

.image-slider-container {
height: 600px;
margin: 0 auto;
width: 400px;
}

.image-slider-container .slick-prev:before,
.image-slider-container .slick-next:before {
color: black;
}
.dropdown-label {
margin-bottom: 12px;
font-size: 16px;
font-weight: bold;
color: #333;
}

.toggle-switch {
display: flex;
align-items: center;
position: relative;
width: 200px;
height: 40px;
background-color: #e0e0e0;
border-radius: 20px;
overflow: hidden;
cursor: pointer;
}

.toggle-switch input[type="radio"] {
display: none;
}

.toggle-option {
flex: 1;
text-align: center;
line-height: 40px;
color: #666;
transition: color 0.3s;
}

.toggle-option:hover {
background-color: #d0d0d0;
}

.toggle-slider {
position: absolute;
top: 0;
left: 0;
width: 50%;
height: 100%;
background-color: #007bff;
border-radius: 20px;
transition: left 0.3s, background-color 0.3s;
}

input[type="radio"]#pdf:checked ~ .toggle-slider {
left: 0;
}

input[type="radio"]#image:checked ~ .toggle-slider {
left: 50%;
}

input[type="radio"]#pdf:checked + label {
color: #000;
}

input[type="radio"]#image:checked + label {
color: white;
}

input[type="radio"]#pdf:not(:checked) + label {
color: #666;
}

input[type="radio"]#image:not(:checked) + label {
color: #666;
}

input[type="radio"]#pdf:checked + .toggle-option {
color: white;
}

input[type="radio"]#image:checked + .toggle-option {
color: white;
}

@media screen and (max-width: 768px) {
.pdf-name-dash-mobile{
  font-size: 16px;
}

}

.slider-index {
position: absolute;
bottom: 10px;
left: 50%;
transform: translateX(-50%);
background-color: rgba(0, 0, 0, 0.5);
color: white;
padding: 5px 10px;
border-radius: 5px;
font-size: 16px;
}


.filter-container-n-a {
  display: flex;
  gap: 0.25rem; 
  margin-top: 0.25rem; 
}

.filter-item-n-a {
  display: flex;
  border: 2px solid #3b82f6; 
  border-radius: 0.5rem; 
  overflow: hidden;
}

.filter-type-n-a {
  background-color: #bfdbfe; 
  color: #1d4ed8; 
  font-weight: 600; 
  padding: 0.25rem 0.5rem; 
}

.filter-value-n-a {
  padding: 0.25rem 0.5rem;
}
