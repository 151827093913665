.footer-base {
  margin-top: 20px;
  padding: 20px;
  text-align: center;
  background-color: #f8f9fa;
  font-family: Arial, sans-serif;
  font-size: 14px;
  color: #333;
  display: flex;
  justify-content: space-around;
}

.footer-links {
  margin-bottom: 10px;
}

.footer-links a {
  text-decoration: none;
  color: #007bff;
  margin: 0 10px;
}

.divider {
  margin: 0 5px;
  color: #333;
}

.footer-divider {
  margin: 20px 0;
  border: none;
  border-top: 1px solid #ddd;
}

.footer-info {
  display: flex;
  justify-content: center;
  gap: 5px;
  color: #333;
}
